<template>
    <div class="box">
        <div class="box-header">
            <h4>{{ pageTitle }}</h4>
        </div>

        <div class="fom-box">

            <el-form
                class="ff"
                style="margin-right:200px;"
                v-if="model"
                :model="model"
                ref="model"
                label-width="140px"
                label-suffix=":"
            >

                <el-form-item label="条码前缀">
                    <el-input
                        style="width:200px;"
                        v-model="model.name_top"
                    ></el-input>
                    <code style="color:#909399;"> 默认空</code>
                </el-form-item>
                <el-form-item label="条码分辨率">
                    <el-input
                        style="width:200px;"
                        v-model="model.scale"
                    ></el-input>
                    <code style="color:#909399;"> 默认3</code>
                </el-form-item>

                <el-form-item label="条码文字大小">
                    <el-input
                        style="width:200px;"
                        v-model="model.fontSize"
                    ></el-input>
                    <code style="color:#909399;"> 默认0，为不设置文字</code>
                </el-form-item>

                <el-form-item label="条码厚度或高度">
                    <el-input
                        type="number"
                        :min="0"
                        style="width:200px;"
                        v-model="model.thickness"
                    ></el-input>
                    <code style="color:#909399;"> 默认25</code>
                </el-form-item>

                <!-- <el-form-item
                label="商品码类型"
                >

                    <el-radio
                        v-model="model.type"
                        label="1"
                    >条形码</el-radio>
                    <el-radio
                        v-model="model.type"
                        label="2"
                    >二维码</el-radio>
                </el-form-item> -->

                <el-form-item label="">
                    <code style="color:#909399;"> 一键生成针对所有商品码 </code>
                </el-form-item>

                <el-form-item>
                    <el-button
                        type="primary"
                        size="medium"
                        @click="onSubmit"
                        :loading="submitLoading"
                    >一键生成</el-button>
                    <el-button
                        type="primary"
                        size="medium"
                        @click="previewGo"
                        :loading="previewLoading"
                    >预览</el-button>
                </el-form-item>
            </el-form>
            <!-- 预览区域 -->
            <div class="preview-box">
                <div style="margin-bottom: 70px;">预览区域</div>
                <img
                    :src="previewUrl"
                    class="img"
                    alt=""
                >
            </div>
        </div>

    </div>
</template>

<script>
import flatry from '@admin/utils/flatry'
import QrBarCodeService from '@admin/services/QrBarCodeService'
export default {
  name: '',
  data () {
    return {
      previewLoading: false,
      submitLoading: false,
      pageTitle: '创建桌号',
      model: {
        type: '1'
      },
      previewUrl: null
    }
  },

  methods: {
    async onSubmit () {
      this.submitLoading = true

      const { data } = await flatry(QrBarCodeService.create(this.model))

      if (data) {
        this.$message.success(data.msg)

        this.$router.replace({ path: '/code/' })
      }
      this.submitLoading = false
    },
    async previewGo () {
      this.previewLoading = true
      const { data } = await flatry(QrBarCodeService.preview(this.model))
      if (data) {
        console.log(data.data)
        this.previewUrl = data.data
        this.previewLoading = false
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.fom-box {
    display: flex;
    .ff {
        min-width: 400px;
    }
}
.preview-box {
    text-align: center;
    overflow: auto;
    .img {
    }
}
</style>
